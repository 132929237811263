/************ Custom Font ************/
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Book.ttf');
  font-weight: 400;
}

.container {
  position: relative;

}

.container img {
  width: 60%;
  margin: 0 auto 15px auto;
}

.meter {
  position: absolute;
  font-weight: bold;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);

  font-size: 1.5vw;
}




.projectsBkgImg {

  

  height: 100vh;
  padding-top: 2px;
  opacity: 0.5;
}

.descImg {
  background-image: url('./assets/img/abc.jpg');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  padding: 15vh 10vw ;

}




/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #000000 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

/* @media (min-width:1700px) {
  main .container {
    max-width: 100%;
    padding: 0 100px;
  }
} */

p.success {
  color: green;
}

p.danger {
  color: red;
}

/************ Navbar Css ************/



nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32 ease-in-out;
}

nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff;
  letter-spacing: 0.8px;
  padding: 0 5px;
  margin: 0 20px;
  font-size: 18px;
  opacity: 0.75;
  transition: all 0.15s ease-out;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover {
  opacity: 1;
  text-shadow: 2px 2px 4px white;


}

nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  background-color: #fff;
  color: #333;
  padding: 0 10px;
}

span.navbar-text {
  display: flex;
  flex: space-between;
  align-items: center;
  margin-left: 5%;
  margin-right: 5%;
}

/* .iconLinkedin {

  border: 1px solid white;
  width:  min(7vw, 20px + 0.5vw);
  height:  min(7vw, 20px + 0.5vw);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.5vw;
} */

/* .iconLinkedin:hover {
  background-color: #fff;
    transition: 0.4s ease-in-out;
}

.iconLinkedin i {
  color: white;
  font-size:  min(7vw, 20px + 0.5vw);

}

.iconLinkedin i:hover {
  color: black;

} */

.iconBox {
  /* position: relative; */
margin-right: 1vw;
}

.iconBox img {
  width:  min(7vw, 20px + 0.5vw);
  margin: 0;
  padding: 0;
}

 

.iconBox:hover {
  transition: 0.2s ease-in-out;
  scale: 1.06;
}


.linka:hover {
  background-color: #fff;
  border: none;
}

/* .iconGithub i {
  color: white;
  font-size:  min(7vw, 20px + 0.5vw);

}

.iconGithub i:hover {
  color: rgb(255, 115, 0);

  /* transition: 0.3s ease-in-out; */
/* }  */


.connect-link:link
{
  text-decoration: none;
  color: white;
}
.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 1% 4%;
  font-size:  min(3vw, 10px + 0.5vw);
  margin-left: 2%;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}

.navbar-text button span {
  z-index: 1;
}

.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.navbar-text button:hover {
  color: #121212;
}

.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}

nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: '';
  z-index: 2;
  transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

/************ description Css ************/
.description {
  margin-top: 0;
  /* margin-bottom: 60px; */
  margin-bottom: 0vh;
  /* padding: 220px 0 100px 0; */
  height: 120vh;

}

.intro {
  /* font-size: 2vw; */
}

.bckgImg {
  height: 100vh;
}

.description .title {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 2px 5px;
  background: linear-gradient(90.21deg, rgba(170, 54, 79, 0.5) -5.91%, rgba(47, 189, 158, 0.5) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size:  min(5.5vw, 50px);
  margin-bottom: 1vh;
  /* display: inline-block; */
  animation: updown 3s linear infinite;
  text-align: center;

}

.description h4 {
  /* font-size: 2vw; */
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.5;
  margin-bottom: 10px;
  text-shadow: 2px 2px #000000;
  display: block;
}

.description p {
  color: #c7c4c4;
  /* font-size: 1vw; */
  letter-spacing: 0.8px;
  line-height: 1.5em;
  text-shadow: 2px 2px #000000;
  padding-top: 2vh;
}


/* .description img {
  animation: updown 3s linear infinite;
} */
@keyframes updown {
  0% {
    transform: translateY(-5px);
  }

  50% {
    transform: translateY(3px);
  }

  100% {
    transform: translateY(-5px);
  }
}

.intro>.wrap {
  border-right: 0.08em solid rgb(255, 217, 0);
  text-shadow: 2px 2px #ff0000;
  color: rgb(255, 196, 0);

}

/************ Skills Css ************/
.skill {
  padding: 20vh 0 10vh 0vw;
  position: relative;
  background: linear-gradient(90.21deg, #000100 -5.91%, #001d3b 111.58%);

}


.image-quote
{
  display: flex;
  position: relative;
  align-items: center;  
  justify-content: center;
  text-align: center;
}

.quote
{
  position: absolute;
  color: rgb(255, 208, 1);
  background-color: rgba(0, 27, 59, 0.815);
  /* font-size: 3vw; */
  font-size:  min(2.5vw, 30px);

  margin: 0px 15vw;
  border-top-left-radius: 3vw;
  border-bottom-right-radius: 3vw;
  border-top-right-radius: 1vw;
  border-bottom-left-radius: 1vw;
  text-shadow: 2px 2px 4px rgba(216, 16, 16, 0.815);
  padding: 1.5vw;
  font-family: 'Courier New', Courier, monospace
}

.skill-bx {
  background: linear-gradient(40.21deg, rgba(86, 228, 140, 0.178) -5.91%, rgba(201, 130, 130, 0.068) 100.58%);
  /* background: linear-gradient(90.21deg, #69696b 0.91%, #000000 190.58%); */
  padding-top: 0%;
  border-radius: 64px;
  text-align: center;
  padding: 2vw 2vw 5vw 2vw;
  margin-top: -60px;
  margin-bottom: 5vw;

}

.skill h2 {
  font-size:  min(9vw, 50px);

  font-weight: 700;
  padding-bottom: 3vw;

}

.meter {
  color: #eecd61;
  font-size:  min(8vw, (40px ));
  letter-spacing: 0.8px;
  line-height: 1.5em;
}

.skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}


/************ Projects Css ************/
.project {
  padding-bottom: 20vh;
  /* position: relative; */
  background: linear-gradient(90.21deg, #000100 -5.91%, #001d3b 111.58%);

}

.project-container{
/* margin: 0 10%;  */
/* display: flex; */
/* flex-wrap: wrap; */
}

.project h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
  padding-bottom: 1vw;
}

.project p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}


.project-card {
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 1px 1px 20px 2px rgba(216, 157, 81, 0.315);

  /* width: 40vw;
  height: 30vh; */
  /* flex: 250%; */
  /* max-width: 25%; */
  /* margin: 3%; */

}

.project-card img {
  width: 100%;
  height: 100%;

}

.project-card::before {
  content: "";
  /* background: linear-gradient(0deg, #c11e38 -10%, #220b34 130%); */
  background: -webkit-linear-gradient(100deg, hsla(254, 68%, 9%, 1) 33%, rgb(65, 1, 134) 66%, rgb(51, 22, 104) 92%, hsla(316, 41%, 93%, 1) 0%);
  opacity: 0.95;
  border-radius: 15px;

  position: absolute;
  width: 100%;
  height: 0%;
  transition: 0.4s ease-in-out;

}

.project-card:hover::before {
  height: 100%;
}

.proj-txtx {
  position: absolute;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;

}

.project-card:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}

.project-card:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}

.proj-txtx h4 {
  font-size:  min(5vw, 10px + 0.5vw);
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
  color: #ffc400;
}

.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size:  min(3vw, 10px + 0.5vw);
  letter-spacing: 0.8px;
}


.iconGithubProject {

margin-top: 3%;
}

.iconGithubProject img {
  color: white;
  width:  min(7vw, 20px + 0.5vw);
  transition: 0.1s ease-in-out;

}

.iconGithubProject i:hover {
  color: rgb(255, 196, 0);
  font-size:  min(8vw, 35px + 0.5vw);

}


/************ Projects Css ************/
.contact {
  /* background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%); */
  background: linear-gradient(90.21deg, #000100 -5.91%, #001d3b 111.58%);
  padding: 20vh 0 200px 0;



}


.contact img {
  width: 92%;
}

.contact h2 {
  font-size: 45px;
  font-weight: 700;
  margin-top: 60px;
  text-align: center;
}


.contact h6 {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 30px;
  text-align: center;
}

.contact form input,
.contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.151);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: rgb(255, 255, 255);
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}

.contact form input:focus,
.contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}

.contact form input::placeholder,
.contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.contact form input:focus::placeholder,
.contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}

.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}

.contact form button span {
  z-index: 1;
  position: relative;
}

.contact form button:hover {
  color: #fff;
}

.contact form button::before {
  content: "";
  background: #0061b1;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}

.contact form button:hover::before {
  width: 100%;
}


.contact form button:active{
  background-color: #d69e05;
  transform: scale(0.85);

}

/************ Footer Css ************/
.footer {
  padding: 5vh 10vw 5vh 10vw;
  background: linear-gradient(90deg, rgb(31, 0, 46) 0%, rgb(7, 22, 28) 100%);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-top: 1px solid rgb(68, 68, 68);
}


.footer p {
  font-weight: 400;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}

.social-icon-footer
{
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 20px;

}